@import "../../theme/automate.less";

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  padding: 0 16px;
  box-shadow: 1 1px 4px rgba(0,21,41,.08);
}

.sidebar-toggle {
  font-size: 18px;
  margin-right: auto;
  cursor: pointer;
}