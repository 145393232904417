@import "../../theme/automate.less";

.user-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  // border-left: 1px solid @gray;
  border-right: 2px solid @gray;
  padding: 0 16px;
}

.user-menu--icon {
  margin-right: 8px;
}

.user-menu--text {
  font-size: 16px;
}