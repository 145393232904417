@import "~theme/automate.less";

.login {
  &-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: @black3;

    .ant-form-item-label > label {
      color: @white2;
    }
  }

  &-title {
    color: @yellow2;
    margin-bottom: 48px;
    font-weight: 700;
  }

  &-error {
    margin-bottom: 24px;
  }

  &-remember-text {
    color: @white2;
  }
}
