@import "../../theme/automate.less";

.content {
  &--wrapper {
    display: flex;
    flex-direction: column;
  }

  &--header {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: space-between;
    margin-top: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }

  &--title {
    margin-right: auto;
  }

  &--pre {
    margin: 24px 16px 0;
    padding: 16px;

    &--white {
      background: @white2;
    }
  }

  &--post {
    margin: 0px 16px 24px;
    padding: 16px;

    &--white {
      background: @white2;
    }
  }

  &--container {
    margin: 24px 16px;
    min-height: 280px;

    &--white {
      background: @white2;
    }

    &--with--padding {
      padding: 24px 16px;
    }
  }
}